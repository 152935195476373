import React from "react";
import classes from "./Brands.module.scss";
import naar from "../../assets/Brands/naar.png";
import zen1 from "../../assets/Brands/zengaz1.png";
// import zen2 from "../../assets/Brands/zengaz2.png";
// import brand1 from "../../../assets/Brands/brand1.png";
// import brand2 from "../../../assets/Brands/brand2.png";

interface IBrandsProps {}
const Brands: React.FC<IBrandsProps> = () => {
  return (
    <section className={classes.brands__container}>
      <div className={classes.col}>
        <img className={classes.naar__image} src={naar} alt="naar" />
      </div>
      <div className={classes.col}>
        <div className={classes.imgs__container}>
          <img className="" src={zen1} alt="naar" />
          {/* <img className="" src={zen2} alt="naar" /> */}
        </div>
      </div>
    </section>
  );
};

export default Brands;
