import React, { useRef, useState } from "react";
import TopSection from "../Components1/HomePage/TopSection";
import classes from "./MainPage.module.scss";
// import {
//   Animator,
//   ScrollContainer,
//   ScrollPage,
//   batch,
//   Fade,
//   FadeIn,
//   FadeOut,
//   Move,
//   MoveIn,
//   MoveOut,
//   Sticky,
//   StickyIn,
//   StickyOut,
//   Zoom,
//   ZoomIn,
//   ZoomOut,
// } from "react-scroll-motion";
import Products from "../Components/Products/Products";
// import Naar from "../Components/Naar/Naar";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import WhoWeAre from "../Components/WhoWeAre/WhoWeAre";
import TheVision from "../Components/TheVision/TheVision";
import BigDreams from "../Components/TheVision/BigDreams";
import Brands from "../Components/Brands/Brands";
import JoinUs from "../Components/JoinUs/JoinUs";
import ContactUs from "../Components/ContactUs/ContactUs";
import logo from "../assets/HeaderFooter/NAAR international Corporate Logo.png";
import Modal from "react-modal";
import FormsModal from "../Components/Forms/FormsModal";

gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollToPlugin);

ScrollTrigger.normalizeScroll(true);

const customStyles = {
  overlay: {
    zIndex: 10000, // Ensure this is higher than other elements on the page
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Optional: darken the background
  },
  content: {
    height: "100dvh",
    width: "100vw",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#000",
    zIndex: 999999,
  },
};

interface IMainPageProps {}
const MainPage: React.FC<IMainPageProps> = () => {
  const [tl, setTl] = useState<gsap.core.Timeline>();
  const [showVideo, setShowVideo] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<string>("");

  const allContainer = useRef<HTMLDivElement>(null);
  const whoWeAreRef = useRef<HTMLDivElement>(null);
  const visionRef = useRef<HTMLDivElement>(null);
  const ourMissionRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  // useGSAP(() => {
  //   const tl = gsap.timeline();

  //   setTl(tl);
  // }, []);

  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  });

  const onVideoEnded = () => {
    // getUpdateParameters("down", 1);
    setShowVideo(false);
    changeSection(5, true);
  };

  const openFormModal = (f: string) => {
    setForm(f);
    setModalOpen(true);
  };

  const changeSection = (sectionNumber: number, immediate?: boolean) => {
    if (sectionNumber === 0) {
      gsap.to(window, {
        duration: 1, // 1 second scroll animation
        scrollTo: {
          y: whoWeAreRef.current ? whoWeAreRef.current : "", // Scroll to the ref
          offsetY: -window.innerHeight, // Scroll 50px past the element (negative means scrolling past)
        }, // Scroll to the ref
        ease: "power2.out", // Ease for a smooth effect
      });
    } else if (sectionNumber === 1) {
      gsap.to(window, {
        duration: 1, // 1 second scroll animation
        scrollTo: {
          y: visionRef.current ? visionRef.current : "", // Scroll to the ref
          offsetY: -window.innerHeight * 0.2, // Scroll 50px past the element (negative means scrolling past)
        }, // Scroll to the ref
        ease: "power2.out", // Ease for a smooth effect
      });
    } else if (sectionNumber === 2) {
      gsap.to(window, {
        duration: 1, // 1 second scroll animation
        scrollTo: {
          y: ourMissionRef.current ? ourMissionRef.current : "", // Scroll to the ref
          offsetY: 150, // Scroll 50px past the element (negative means scrolling past)
        }, // Scroll to the ref
        ease: "power2.out", // Ease for a smooth effect
      });
    } else if (sectionNumber === 3) {
      gsap.to(window, {
        duration: 1, // 1 second scroll animation
        scrollTo: {
          y: teamRef.current ? teamRef.current : "", // Scroll to the ref
          offsetY: 10, // Scroll 50px past the element (negative means scrolling past)
        }, // Scroll to the ref
        ease: "power2.out", // Ease for a smooth effect
      });
    } else if (sectionNumber === 4) {
      gsap.to(window, {
        duration: 1, // 1 second scroll animation
        scrollTo: {
          y: contactRef.current ? contactRef.current : "", // Scroll to the ref
          offsetY: 100, // Scroll 50px past the element (negative means scrolling past)
        }, // Scroll to the ref
        ease: "power2.out", // Ease for a smooth effect
      });
    } else if (sectionNumber === 5) {
      gsap.to(window, {
        duration: immediate ? 0 : 1, // 1 second scroll animation
        scrollTo: {
          y: 0, // Scroll to the ref
          offsetY: -window.innerHeight, // Scroll 50px past the element (negative means scrolling past)
        }, // Scroll to the ref
        ease: "power2.out", // Ease for a smooth effect
      });
    }
  };

  // const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  // const FadeUp = batch(Fade(), Move(), Sticky());
  return (
    <main className={classes.main__page}>
      <ReactLenis root>
        <Modal
          isOpen={showVideo}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={() => setShowVideo(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className={classes.formContainer}>
            <div className={classes.left}>Contact us</div>
            <div className={classes.right}>
              <TopSection onVideoEnded={onVideoEnded} />
            </div>
          </div>
        </Modal>

        <div ref={allContainer}>
          {/* {showMenu && (
            <div className="dropdown__section">
              <div onClick={() => changeSection(0)}>Home</div>
              <div onClick={() => changeSection(1)}>Our vision</div>
              <div onClick={() => changeSection(2)}>Our mission</div>
              <div onClick={() => changeSection(3)}>Team</div>
              <div onClick={() => changeSection(4)}>Get in touch</div>
            </div>
          )} */}
          {!showVideo && !modalOpen && (
            <header className="blurry-background">
              <div
                className="side__sections side__section1"
                onClick={() => changeSection(5)}
              >
                <img src={logo} alt="logo" />
              </div>
              <div className="middle__section">
                <div className="links" onClick={() => changeSection(0)}>
                  Who We Are
                </div>
                <div className="links" onClick={() => changeSection(1)}>
                  Our vision
                </div>
                <div className="links" onClick={() => changeSection(2)}>
                  Our mission
                </div>
                <div className="links" onClick={() => changeSection(3)}>
                  Team
                </div>
                <div className="links" onClick={() => changeSection(4)}>
                  Get in touch
                </div>
              </div>
              <div className="side__sections side__section2">
                {/* <div onClick={(e) => toggleMenu(e)} ref={menuRef}>
                <img src={menu} alt="menu_icon" />
              </div> */}
              </div>
            </header>
          )}
          <Products />
          <div className={classes.main__page__spacer}></div>
          <div ref={whoWeAreRef}>
            <WhoWeAre />
          </div>
          <div className={classes.main__page__spacer__2}></div>
          <div ref={visionRef}>
            <TheVision />
          </div>
          <div ref={ourMissionRef}>
            <BigDreams />
          </div>
          <Brands />
          <div ref={teamRef}>
            <JoinUs openFormModal={openFormModal} />
          </div>
          {/* <div className={classes.main__page}></div> */}
          <div ref={contactRef}>
            <ContactUs openFormModal={openFormModal}>
              <footer className={classes.footer}>
                <div className={classes.left}>
                  <div>
                    <img src={logo} alt="logo" />
                  </div>
                  <div className={classes.links__container}>
                    <div onClick={() => changeSection(0)}>Home</div>
                    <div onClick={() => changeSection(1)}>Our vision</div>
                    <div onClick={() => changeSection(2)}>Our mission</div>
                    <div onClick={() => changeSection(3)}>Team</div>
                    <div onClick={() => changeSection(4)}>Get in touch</div>
                  </div>
                </div>
                <div className={classes.right}>
                  © 2024 Naar International. All rights reserved. Unauthorized
                  use is prohibited.
                </div>
              </footer>
            </ContactUs>
          </div>
        </div>
      </ReactLenis>

      <FormsModal
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        form={form}
      />

      {!showVideo && !modalOpen && (
        <div className={classes.scroll__down}>&#8811;</div>
      )}
    </main>
  );
};

export default MainPage;
