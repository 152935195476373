import React from "react";
import "./ContactUs.scss";
import headimg from "../../assets/ContactUs/Group 126.png";
import arrow from "../../assets/JoinUs/Vector.png";
interface IContactUsProps {
  openFormModal: (form: string) => void;
  children: any;
}
const ContactUs: React.FC<IContactUsProps> = ({ openFormModal, children }) => {
  return (
    <section className="contact__us__container">
      <div className="content__container">
        <img className="contact__img" src={headimg} alt="" />
        <div className="contact__us__header">Contact Us</div>
        <div
          className="contact__us__send__message"
          onClick={() => {
            openFormModal("contact");
          }}
        >
          Send a message{" "}
          <img className="send__msg__img" src={arrow} alt="arrow" />
        </div>
      </div>
      {children}
    </section>
  );
};

export default ContactUs;
