import React, { useRef } from "react";
import classes from "./WhoWeAre.module.scss";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import Lighter1 from "../../assets/Lighters/Lighter1.png";
import Lighter2 from "../../assets/Lighters/Lighter2.png";
import Lighter3 from "../../assets/Lighters/Lighter3.png";
import logo from "../../assets/HeaderFooter/NAAR international Corporate Logo.png";
import lighter4 from "../../assets/Lighters/lighter4.png";

gsap.registerPlugin(useGSAP, ScrollTrigger);

interface IWhoWeAreProps {}
const WhoWeAre: React.FC<IWhoWeAreProps> = () => {
  const whowearecontainer = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    // let tl = gsap.timeline({});
    // alert(1);

    /**
     * Change section background
     */

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: whowearecontainer.current,
        pin: true,
        start: "bottom bottom",
        // start: "top top",
        end: `+=${window.innerHeight * 3}`,
        scrub: true,
        pinSpacing: false,
      },
    });

    tl &&
      tl.fromTo(
        whowearecontainer.current,
        { backgroundColor: "#000" },
        { backgroundColor: "#000" }
      );

    /**
     * Show paragraph
     */
    tl &&
      tl.fromTo(
        ".paragraph__container",
        {
          opacity: 0,
        },
        { opacity: 1 }
      );

    /**
     * Slide lighters up
     */
    tl &&
      tl
        .from(
          ".lighters__container",
          {
            y: 1500,
            opacity: 0,
          },
          "<"
        )
        .from(
          ".lighter__4",
          {
            x: -350,
            y: 1500,
            opacity: 0,
          },
          "<"
        );

    tl &&
      tl
        .to(".lighters__container", {
          y: -1000,
        })
        .to(
          ".paragraph__container",
          {
            y: -1000,
          },
          "<"
        )
        .to(
          ".lighter__4",
          {
            bottom: "25%",
            right: "-10%",
            rotate: "-40deg",
            height: "30vh",
            width: "80vw",
          },
          "<"
        );

    tl &&
      tl.to(".lighter__4", {
        bottom: "30%",
        right: "-10%",
        rotate: "-10deg",
        height: "30vh",
        width: "100vw",
      });

    tl &&
      tl.to(".lighter__4", {
        bottom: "0",
        right: "0",
        rotate: "0deg",
        height: "100dvh",
        width: "100vw",
      });
  });
  return (
    <div
      ref={whowearecontainer}
      className={`${classes.page__section}`}
      // ref={sectionRef}
    >
      <div
        className={`paragraph__container ${classes.text__content__container}`}
      >
        <div className={`${classes.text__content__sub__container}`}>
          <div className={classes.text__content__title}>WHO WE ARE</div>
          <div className={classes.text__content}>
            At NAAR, we leverage our creative marketing, excellent service and
            technology expertise to craft innovative, differentiated and
            outstanding smoking-related brands.
          </div>
          <div className={classes.text__content}>
            We have a proven track record of delivering state-of-the-art
            products to some of the leading players in the consumer goods,
            retail & FMCG sectors. We pride ourselves with a deep understanding
            of consumer needs & go-to-market strategies.
          </div>
        </div>
      </div>
      <div className={`lighters__container ${classes.wwd__bg__text}`}>
        <div className={`${classes.lighters__container}`}>
          <div className={classes.lighters__sub__container}>
            <img className={classes.lighter__1} src={Lighter1} alt="" />
            <img className={classes.lighter__2} src={Lighter2} alt="" />
            <img className={classes.lighter__3} src={Lighter3} alt="" />
          </div>
        </div>
        <div className={`${classes.bg__text__container}`}>
          <div className={`${classes.content__column} ${classes.left__column}`}>
            <div>N</div>
            <div className={classes.shifted__letter__left}>A</div>
          </div>
          <div
            className={`${classes.content__column} ${classes.right__column}`}
          >
            <div>A</div>
            <div className={classes.shifted__letter__left}>R</div>
          </div>
        </div>
      </div>
      <div className={`lighter__4 ${classes.lighter__4}`}>
        <div className={classes.lighter__4__images__container}>
          <img
            src={lighter4}
            alt="sky"
            className={classes.lighter__4__image__sky}
          />
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
