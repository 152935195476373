import React, { useRef } from "react";
import classes from "./JoinUs.module.scss";
import joinus from "../../assets/JoinUs/JoinUs.png";
// import AnimatedPhotoMask from "./AnimatedPhotoMask";
// import AnimatedText from "./AnimatedText";
import arrow from "../../assets/JoinUs/Vector.png";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
gsap.registerPlugin(useGSAP, ScrollTrigger);

interface IJoinUsProps {
  openFormModal: (form: string) => void;
}

const JoinUs: React.FC<IJoinUsProps> = ({ openFormModal }) => {
  const juRef = useRef<HTMLDivElement>(null);
  useGSAP(() => {
    // let tl = gsap.timeline({});
    // alert(1);

    /**
     * Change section background
     */

    gsap.from(".join__us__header", {
      x: -250,
      opacity: 0.2,
      scrollTrigger: {
        trigger: juRef.current,
        pin: false,
        pinSpacing: false,
        start: "top bottom",
        end: `top 55%`,
        scrub: true,
      },
    });

    gsap.from(".join__us__text", {
      x: -250,
      opacity: 0.2,
      scrollTrigger: {
        trigger: ".join__us__page",
        start: "top bottom",
        end: `top 35%`,
        scrub: true,
      },
    });

    gsap.from(".join__us__link", {
      x: -250,
      opacity: 0.2,
      scrollTrigger: {
        trigger: ".join__us__page",
        start: "top 80%",
        end: `bottom bottom`,
        scrub: true,
      },
    });

    gsap.to(".mask", {
      width: 0,
      scrollTrigger: {
        trigger: ".join__us__page",
        start: "top 80%",
        end: `60% bottom`,
        scrub: true,
      },
    });
  });
  return (
    <section
      ref={juRef}
      className={`join__us__page ${classes.join__us__container}`}
    >
      <div className={`${classes.col} ${classes.text__col}`}>
        <div className={`join__us__header ${classes.join__us__header}`}>
          Join Us
        </div>
        <div className={`join__us__text ${classes.join__us__text}`}>
          It takes an entire team to achieve big goals and make great things
          happen. Together, we work hard and play hard. With our modern business
          approach and environment, at NAAR International we offer you the
          chance of being surrounded by smart, creative and ambitious people,
          while also gaining international exposure with global brands.
        </div>
        <div
          onClick={() => {
            openFormModal("join");
          }}
        >
          <div className={`join__us__link ${classes.join__us__link}`}>
            Sounds good? Come join us!
            <img src={arrow} alt="arrow" />
          </div>
        </div>
      </div>
      <div className={classes.col}>
        <img className={classes.join__us__img} src={joinus} alt="join_us" />
        <div className={`mask ${classes.mask}`}></div>
        {/* <AnimatedPhotoMask /> */}
      </div>
    </section>
  );
};

export default JoinUs;
