import React, { useRef, useState } from "react";
import zengaz from "../../assets/LightersZenngaz.png";
import classes from "./products.module.scss";
import naar from "../../assets/PizzaLighter.png";
import naar1 from "../../assets/Pool_Party.png";
import WhoWeAre from "../WhoWeAre/WhoWeAre";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";

// import Lighter1 from "../../assets/Lighters/Lighter1.png";
// import Lighter2 from "../../assets/Lighters/Lighter2.png";
// import Lighter3 from "../../assets/Lighters/Lighter3.png";
// import logo from "../../assets/HeaderFooter/NAAR international Corporate Logo.png";
// import lighter4 from "../../assets/Lighters/lighter4.png";

gsap.registerPlugin(useGSAP, ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

interface IProductsProps {}
const Products: React.FC<IProductsProps> = () => {
  const container = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const whoweareRef = useRef<HTMLDivElement>(null);
  const zengaz_image = useRef<HTMLDivElement>(null);
  const header1Ref = useRef<HTMLDivElement>(null);
  const header2Ref = useRef<HTMLDivElement>(null);
  const brandZengazRef = useRef<HTMLDivElement>(null);

  // const tl = useRef<any>(null);

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: productsRef.current,
        pin: productsRef.current,
        start: "top top",
        // start: "top top",
        end: `+=${window.innerHeight * 5}`,
        scrub: 1,
        pinSpacing: false,
      },
    });

    /**
     * Load meet our iconic brands text
     */
    tl &&
      tl.from(".text__header", {
        x: -150,
        y: 150,
        opacity: 0,
        scale: 0.6,
        duration: 50,
      });

    /**
     * Load Zengaz
     * Load images
     */
    tl &&
      tl
        .from(".text__zengaz", {
          x: -150,
          opacity: 0,
          duration: 50,
        })
        .from(
          zengaz_image.current,
          {
            y: 800,
            scale: 0.8,
            duration: 50,
          },
          "<"
        );

    /**
     * Remove zengaz
     * Remove zengaz images
     */
    tl &&
      tl
        .to(".text__zengaz", {
          x: 1000,
          duration: 50,
        })
        .to(
          zengaz_image.current,
          {
            y: -800,
            duration: 50,
          },
          "<"
        );

    /**
     * Load Naar text
     * Load Naar images (x2)
     */
    tl &&
      tl
        .from(".text__naar", {
          x: -550,
          opacity: 0,
          duration: 50,
        })
        .from(
          ".pool__party",
          {
            y: 800,
            duration: 50,
          },
          "<"
        )
        .from(
          ".naar_img",
          {
            y: 800,
            duration: 50,
          },
          "<"
        );

    /**
     * Remove Pool Party
     * Animate Naar pizza lighter image
     */

    tl &&
      tl
        .to(".naar_img", {
          left: "50%",
          xPercent: -50,
          yPercent: -30,
          duration: 50,
        })
        .to(
          ".pool__party",
          {
            y: -1000,
            x: 550,
            duration: 50,
          },
          "<"
        )
        .to(
          ".text__header",
          {
            y: -1000,
            duration: 50,
          },
          "<"
        )
        .to(
          ".text__naar",
          {
            y: -1000,
            duration: 50,
          },
          "<"
        );

    tl &&
      tl.to(".naar_img", {
        left: "30%",
        xPercent: -50,
        yPercent: 50,
        scale: 1.5,
        rotate: "5deg",
        duration: 50,
      });

    tl &&
      tl
        .to(".naar_img", {
          left: "50%",
          xPercent: -35,
          yPercent: 900,
          scale: 25,
          opacity: 0.5,
          rotate: "10deg",
          duration: 50,
        })
        .to(
          productsRef.current,
          {
            backgroundColor: "transparent",
            opacity: 1,
            duration: 50,
          },
          "<"
        )
        .to(
          ".ps ",
          {
            backgroundColor: "transparent",
            opacity: 1,
            duration: 50,
          },
          "<"
        );

    /**
     * Fade and remove
     */
    tl &&
      tl
        .to(productsRef.current, {
          // backgroundColor: "#000",
          height: "0px",
          duration: 50,
        })
        .to(
          ".naar_img",
          {
            opacity: 0,
            duration: 50,
          },
          "<"
        );
  });

  // useGSAP(() => {
  //   const oh = container.current ? container.current.offsetHeight : 0;
  //   container &&
  //     tl?.to(container.current, {
  //       // scrollTrigger: {
  //       //   trigger: container.current,
  //       //   pin: true,
  //       //   start: () => (oh < window.innerHeight ? "top top" : "bottom bottom"),
  //       //   // start: "top top",
  //       //   // end: "+=1000px",
  //       //   scrub: true,
  //       //   // pinSpacing: false,
  //       // },
  //     });
  //   /**
  //    * Load meet our iconic brands text
  //    */
  //   tl &&
  //     tl.from(".text__header", {
  //       x: -150,
  //       y: 150,
  //       opacity: 0,
  //       scale: 0.6,
  //     });

  //   /**
  //    * Load Zengaz
  //    * Load images
  //    */
  //   tl &&
  //     tl
  //       .from(".text__zengaz", {
  //         x: -150,
  //         opacity: 0,
  //       })
  //       .from(
  //         zengaz_image.current,
  //         {
  //           y: 800,
  //           scale: 0.8,
  //         },
  //         "<"
  //       );

  //   /**
  //    * Remove zengaz
  //    * Remove zengaz images
  //    */
  //   tl &&
  //     tl
  //       .to(".text__zengaz", {
  //         x: 1000,
  //       })
  //       .to(
  //         zengaz_image.current,
  //         {
  //           y: -800,
  //         },
  //         "<"
  //       );

  //   /**
  //    * Load Naar text
  //    * Load Naar images (x2)
  //    */
  //   tl &&
  //     tl
  //       .from(".text__naar", {
  //         x: -550,
  //         opacity: 0,
  //       })
  //       .from(
  //         ".pool__party",
  //         {
  //           y: 800,
  //         },
  //         "<"
  //       )
  //       .from(
  //         ".naar_img",
  //         {
  //           y: 800,
  //         },
  //         "<"
  //       );

  //   /**
  //    * Remove Pool Party
  //    * Animate Naar pizza lighter image
  //    */

  //   tl &&
  //     tl
  //       .to(".naar_img", {
  //         left: "50%",
  //         xPercent: -50,
  //         yPercent: -30,
  //       })
  //       .to(
  //         ".pool__party",
  //         {
  //           y: -1000,
  //           x: 550,
  //         },
  //         "<"
  //       )
  //       .to(
  //         ".text__header",
  //         {
  //           y: -1000,
  //         },
  //         "<"
  //       )
  //       .to(
  //         ".text__naar",
  //         {
  //           y: -1000,
  //         },
  //         "<"
  //       );

  //   tl &&
  //     tl.to(".naar_img", {
  //       left: "30%",
  //       xPercent: -50,
  //       yPercent: 50,
  //       scale: 1.5,
  //       rotate: "5deg",
  //     });

  //   tl &&
  //     tl
  //       .to(".naar_img", {
  //         left: "50%",
  //         xPercent: -35,
  //         yPercent: 900,
  //         scale: 25,
  //         opacity: 0.5,
  //         rotate: "10deg",
  //       })
  //       .to(productsRef.current, {
  //         backgroundColor: "#00aae83f",
  //         opacity: 1,
  //       });

  //   /**
  //    * Fade and remove
  //    */
  //   tl &&
  //     tl
  //       .to(productsRef.current, {
  //         // backgroundColor: "#000",
  //         height: "0px",
  //       })
  //       .to(
  //         ".naar_img",
  //         {
  //           opacity: 0,
  //         },
  //         "<"
  //       );
  //   // .fromTo(
  //   //   whoweareRef.current,
  //   //   {
  //   //     // backgroundColor: "#00aae83f",
  //   //     // opacity: 0,
  //   //     xPercent: "-100%",
  //   //     // height: "0px",
  //   //     // x: 800,
  //   //   },
  //   //   {
  //   //     // backgroundColor: "#000",
  //   //     // opacity: 1,
  //   //     xPercent: "0%",
  //   //     // height: "0px",
  //   //     // x: 800,
  //   //     immediateRender: true,
  //   //   },
  //   //   "<"
  //   // );
  // }, [tl]);

  return (
    <div ref={container}>
      <div ref={productsRef} className={`ps ${classes.page__section}`}>
        <div className={classes.left__column}>
          <div className={classes.image__container} ref={zengaz_image}>
            <img
              src={zengaz}
              alt="zengaz"
              className={`${classes.prod__img__dn}`}
            />
          </div>
        </div>
        <div className={classes.right__column}>
          <div className={classes.text__container}>
            <div ref={header1Ref} className={`text__header`}>
              Meet Our
            </div>
            <div ref={header2Ref} className={`text__header`}>
              Iconic Brands
            </div>
            <div className={classes.second__row_container}>
              <div
                ref={brandZengazRef}
                className={`text__zengaz ${classes.second__row}`}
              >
                ZENGAZ
              </div>
              <div
                ref={brandZengazRef}
                className={`text__naar ${classes.second__row}`}
              >
                NAAR
              </div>
            </div>
          </div>
        </div>
        <img
          src={naar1}
          alt="naar1"
          className={`pool__party ${classes.pool__party__dn}`}
        />
        <img
          src={naar}
          alt="naar"
          className={`naar_img ${classes.image__move__and__scale__dn}`}
        />
      </div>
    </div>
  );
};

export default Products;
