import React from "react";
import Modal from "react-modal";
import ContactUs from "./ContactUs";
import classes from "./modal.module.scss";

interface IFormsModalProps {
  modalOpen: boolean;
  closeModal: () => void;
  form: string;
}

const customStyles = {
  overlay: {
    zIndex: 10000, // Ensure this is higher than other elements on the page
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Optional: darken the background
  },
  content: {
    height: "100dvh",
    width: "100vw",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    backgroundColor: "#000",
    zIndex: 999999999999,
  },
};

const FormsModal: React.FC<IFormsModalProps> = ({
  modalOpen,
  closeModal,
  form,
}) => {
  return (
    <Modal
      isOpen={modalOpen}
      //   onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
      <button onClick={closeModal} className={classes.closeBtn}>
        X
      </button>
      <div className={classes.formContainer}>
        <div className={classes.left}>{form} us</div>
        <div className={classes.right}>
          <ContactUs />
        </div>
      </div>
    </Modal>
  );
};

export default FormsModal;
