import React from "react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import classes from "./TheVision.module.scss";

import a1 from "../../assets/TheVision/BigDreams/1.png";
import a2 from "../../assets/TheVision/BigDreams/2.png";
import a3 from "../../assets/TheVision/BigDreams/3.png";
import a4 from "../../assets/TheVision/BigDreams/4.png";
import a5 from "../../assets/TheVision/BigDreams/5.png";
import a6 from "../../assets/TheVision/BigDreams/6.png";
import a7 from "../../assets/TheVision/BigDreams/7.png";
import a8 from "../../assets/TheVision/BigDreams/8.png";

interface IAnimatedPersonsProps {}
const AnimatedPersons: React.FC<IAnimatedPersonsProps> = ({}) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll();

  const [a, setA] = React.useState<number>(0);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    // console.log(window.innerHeight);

    if (elementRef.current) {
      const rect = elementRef!.current.getBoundingClientRect();

      let factor = 250;

      const r = rect.top / window.innerHeight;

      if (window.innerWidth < 1050) factor = 350;
      if (window.innerWidth < 750) factor = 800;

      if (r >= 0.3 && r <= 1) {
        // console.log(r * 30 + 30);
        setA(r * factor + 50);
      }
    }
  });
  return (
    <div className={classes.right}>
      {/* <div className="grid__cont"> */}
      <div
        ref={elementRef}
        className={classes.animated__persons__container}
        style={{ width: `${a}%` }}
      >
        {/* {a} */}
        <img src={a1} alt="" />
        <img src={a2} alt="" />
        <img src={a3} alt="" />
        <img src={a4} alt="" />
        <img src={a5} alt="" />
        <img src={a6} alt="" />
        <img src={a7} alt="" />
        <img src={a8} alt="" />
      </div>
    </div>
  );
};

export default AnimatedPersons;
